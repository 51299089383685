import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default React.memo(function MetaUrlSetter({ children }) {
  const location = useLocation();

  useEffect(() => {
    const tag = document.querySelector(`[property="og:url"]`);
    tag.content = window.location.origin + (window.location.pathname === '/' ? '' : window.location.pathname);
  }, [location.pathname]);

  return children;
})
